import { ChainId } from '@uniswap/sdk-core'

const PUNXE_BASE_IDO = {
  eth: '0x65Fca4426a3DBBAfE2b28354ab03821d29b35045',
  bakery: '0x856F9EA598C59fd7c320c1796980C5D3bD86B8D4',
}

export const FACTORY_PUNXE_IDO = PUNXE_BASE_IDO

export const IDTOKEN: any = {
  eth: [1, 5],
  doggy: [1, 5],
  doggyNFT: [1, 5],
  doggynft: [1, 5],
  doggycoin: [1, 5],
  bakery: [97, 56],
  punkx: [84531, 8453],
  onecat: [1, 5],
  wbtc: [1, 5],
}

export const CONTRACT_ADDRESSES: { [contract: string]: { [chainId: string]: string } } = {
  wbtc: {
    [ChainId.GÖRLI]: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    [ChainId.MAINNET]: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  },
  doggy: {
    [ChainId.GÖRLI]: '0x1d1a6c18D1EFE9CA87C5cC08c9192F6969448a6F',
    [ChainId.MAINNET]: '0xd2e4B429cBefAF2CaFA9731d66600C44bAc851A4',
  },
  doggyNFT: {
    [ChainId.GÖRLI]: '0x481cbD8b6af174d5476cC049dEEFEa5B1D21CEE0',
    [ChainId.MAINNET]: '0x82Ed14aE600eDa7346545fd5CBC68BA9338AEffA',
  },
  eth: {
    [ChainId.MAINNET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    [ChainId.GÖRLI]: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
  },
  bakery: {
    97: '0xBADb0318b2ECbB1a00E3EFB6961bef059fb55E47',
    56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    84531: '0xBADb0318b2ECbB1a00E3EFB6961bef059fb55E47',
    8453: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  },
  doggycoin: {
    97: '0x1d1a6c18D1EFE9CA87C5cC08c9192F6969448a6F',
    56: '0x0daDDB1729a846A75cc89e610eef590aD8b9F7ca',
    [ChainId.GÖRLI]: '0x20E8258d35f6Fca5688F0101a53993487C55090A',
    [ChainId.MAINNET]: '0x0daDDB1729a846A75cc89e610eef590aD8b9F7ca',
  },
  punkx: {
    84531: '0x74F69E5BFFf895D53121A5038D6081c25AA47AEC',
    8453: '0x28eD8909de1b3881400413Ea970ccE377a004ccA',
  },
  onecat: {
    [ChainId.GÖRLI]: '0xfB9F9154129B4A52E2d2465C0B2Ab99d6eD0865e',
    [ChainId.MAINNET]: '0x508E00D5ceF397B02d260D035e5EE80775e4C821',
  },
}

export const SUPPORTID = [84531, 8453, 56, 97, 1, 5]

export const NETWORK_LABELS: any = {
  97: 'BSC testnet',
  56: 'BSC',
  84531: 'baseGoerli',
  8453: 'base',
  1: 'Ethereum Mainnet',
  5: 'Ethereum testnet',
}

export const TO_DEV_CHAINID: any = {
  bakery: 97,
  eth: 5,
  punkx: 84531,
  doggycoin: 5,
  onecat: 5,
  wbtc: 5,
}

export const TO_MASTER_CHAINID: any = {
  bakery: 56,
  eth: 1,
  punkx: 8453,
  doggycoin: 1,
  onecat: 1,
  wbtc: 1,
}

export const NETWORKk_RPC_URL: any = {
  97: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
  56: 'https://bsc-dataseed.binance.org/',
  84531: 'https://goerli.base.org',
  8453: 'https://developer-access-mainnet.base.org',
  1: 'https://mainnet.infura.io/v3/',
  5: 'https://goerli.infura.io/v3/',
}

export const NETWORKk_URL_MAP: any = {
  97: 'https://test.binance.org/smart',
  56: 'https://bscscan.com/',
  84531: 'https://goerli.basescan.org',
  8453: 'https://basescan.org',
  1: 'https://etherscan.io',
  5: 'https://goerli.etherscan.io',
}

let BAKERY_HOST_MASTER = false
if (window.location.host.indexOf('bakeryswap.org') >= 0) {
  BAKERY_HOST_MASTER = true
} else {
  BAKERY_HOST_MASTER = false // test
}
// BAKERY_HOST_MASTER = true
export const BAKERY_HOST_ORG = BAKERY_HOST_MASTER
