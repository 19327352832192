export const IDOTYPE = Object.freeze({
  PETPOKERIDO: 'PETPOKER',
  RARECAR: 'RARECAR',
  CRYPTODOGGIES: 'CRYPTODOGGIES',
  TOKAU: 'TOKAU',
  XMS: 'XMS',
  GAT: 'GAT',
  DOGGY: 'DOGGY',
  QUICKDOGGY: 'QUICKDOGGY',
  MINTDOGGY: 'MINTDOGGY',
  DOGGYNFT: 'DOGGYNFT',
  DOGGYMINTNFT: 'DOGGYMINTNFT',
  ONECAT: 'ONECAT',
  DOGGYCOIN: 'DOGGYCOIN',
  BITLAND: 'BITLAND',
  BITCAT: 'BITCAT',
  BENDDAO: 'BENDDAO',
  CATNFT: 'CATNFT',
  OPENSKY: 'OPENSKY',
  BITCOINVM: 'BITCOINVM',
  IQ50: 'IQ50',
})

export interface IdoItemProps {
  detailImg: string
  detailImg_phone?: string
  title: string
  supportedCoin: string
  imgWebsite: string
  website: string
  whitelist?: boolean
  des?: string
  illustration?: string
  readMore?: string
  blocksLeft?: string
  delete?: boolean
  links?: any
  limitMax?: any
  leftTexts?: any
  imgWebsiteBg?: boolean
}

export const idoData: IdoItemProps[] = [
  {
    detailImg: require('../../assets/ido/onecat.png').default,
    detailImg_phone: require('../../assets/ido/onecat_phone.png').default,
    title: 'Bitcoin Cats - $1Cat',
    supportedCoin: 'ETH, BAKE, DOGGY, PUNK',
    imgWebsite: '/images/coin.gif',
    imgWebsiteBg: true,
    website: 'https://www.bitcoincats.world',
    whitelist: false,
    des:
      'BitcoinCats is the GameFi platform for the Bitcoin Ecosystem. Through mapping the Bitcoin Assets (BRC20, Ordinals NFT and others) to Ethereum (and other Layer2) networks, BitcoinCats brings many new elements to the Bitcoin Assets, including but not limited to Play2Earn, Staking, Farmland, SocialFi and many others. The mission of BitcoinCats is to build the next-gen gaming ecosystem across Bitcoin and EVM networks, with the communities of both sides, together.\n\nThe limitation for each participant is 2ETH. In case of oversubscription, the excess funds will be returned proportionately. Please note that whitelist members are eligible for a doubled allocation.\n\nAfter the launching: Participants will receive $1cat tokens and excess $ETH refunds within 48 hours after participating in the sale. Note that about half of the $1cat will be distributed on BRC while the other half will be distributed on Ethereum.',
    blocksLeft: '#Blocks Left: ',
    leftTexts: [
      {
        key: 'Launching Amount:',
        value: {
          eth: '2B',
          bakery: '2B',
          doggycoin: '2B',
          punkx: '2B',
        },
        isValue: true,
      },
      {
        key: 'Price:',
        value: {
          eth: '1ETH = 6.25M $1CAT',
          bakery: '1ETH = 6.25M $1CAT',
          doggycoin: '1ETH = 6.25M $1CAT',
          punkx: '1ETH = 6.25M $1CAT',
        },
        isValue: true,
      },
      {
        key: 'WL Round Start:',
        value: {
          eth: 'Dec 19, 8 AM UTC',
          bakery: 'Dec 19, 8 AM UTC',
          doggycoin: 'Dec 19, 8 AM UTC',
          punkx: 'Dec 19, 8 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'Non-WL Round Start:',
        value: {
          eth: 'Dec 19, 9 AM UTC',
          bakery: 'Dec 19, 9 AM UTC',
          doggycoin: 'Dec 19, 9 AM UTC',
          punkx: 'Dec 19, 9 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'End:',
        value: {
          eth: 'Dec 19, 10 AM UTC',
          bakery: 'Dec 19, 10 AM UTC',
          doggycoin: 'Dec 19, 10 AM UTC',
          punkx: 'Dec 19, 10 AM UTC',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Website',
        url: 'https://www.bitcoincats.world',
        icon: require('../../assets/ido/onecat_website.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/BitcoinCats1Cat',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
      {
        name: 'Telegram',
        url: 'https://t.me/BitcoinCats1CAT',
        icon: require('../../assets/ido/onecat_telegram.png').default,
      },
    ],
  },
  {
    detailImg: require('../../assets/ido/bitland.png').default,
    detailImg_phone: require('../../assets/ido/bitland_phone.png').default,
    title: 'BitLand-BLLB',
    supportedCoin: 'ETH, BAKE, DOGGY, PUNK',
    imgWebsite: require('../../assets/ido/bitland_coin.png').default,
    imgWebsiteBg: false,
    website: 'https://bitland.zone/',
    whitelist: false,
    des:
      'BitLand represents a groundbreaking endeavor as the AI-driven 3D Metaverse based on the Bitcoin ecosystem. The mission of BitLand is to cultivate an environment that prioritizes sociability, gaming experience for users and allows users to make use of their Bitcoin Assets (BRC20, Ordinals NFT and others) within the Metaverse. By bridging the assets between ERC20 and BRC20, Bitland will bring many new users into the Bitcoin Ecosystem and narrow the barriers between the Bitcoin ecosystem and the EVM ecosystem.\n\nBitLand is actively integrated into the Bitcoin ecosystem, prioritizing improved user login experiences through major Bitcoin wallet integrations. BitLand will seamlessly incorporate Bitmap and BRC420 Protocol into the product, granting exclusive access to Bitmap holders and utilizing the BRC420 protocol for future Metaverse assets. The full BitLand ecosystem includes five components: BitLand Marketplace, BitLand Map, BitLand World, BitLand Builder, and BitLand DAO. This ensures that BitLand provides a well-rounded experience for players, creators, and investors, contributing to the prosperity of the creator economy.',
    blocksLeft: '#Blocks Left: ',
    leftTexts: [
      {
        key: 'Launching Amount:',
        value: {
          bakery: '5B',
          onecat: '5B',
        },
        isValue: true,
      },
      {
        key: 'Price:',
        value: {
          bakery: '1ETH = 41.6M $BLLB',
          onecat: '1ETH = 41.6M $BLLB',
        },
        isValue: true,
      },
      {
        key: 'Alpha WL Start:',
        value: {
          bakery: 'Dec 28, 8 AM UTC',
          onecat: 'Dec 28, 8 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'Beta WL and Non-WL Start:',
        value: {
          bakery: 'Dec 28, 9 AM UTC',
          onecat: 'Dec 28, 9 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'End:',
        value: {
          bakery: 'Dec 28, 10 AM UTC',
          onecat: 'Dec 28, 10 AM UTC',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Website',
        url: 'https://bitland.zone/',
        icon: require('../../assets/ido/bitland_website.png').default,
        iconphone: require('../../assets/ido/bitland_website_phone.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/Bitland_zone',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
      {
        name: 'Telegram',
        url: 'https://t.me/Bitland_zone',
        icon: require('../../assets/ido/onecat_telegram.png').default,
      },
    ],
  },
  {
    detailImg: require('../../assets/ido/bitcat.jpeg').default,
    detailImg_phone: require('../../assets/ido/bitcat_phone.jpeg').default,
    title: 'Bitcat-BICA',
    supportedCoin: 'ETH, BAKE, DOGGY, PUNK',
    imgWebsite: require('../../assets/ido/bitcat_coin.png').default,
    imgWebsiteBg: false,
    website: 'https://bitcat.copycat.finance',
    whitelist: false,
    des:
      'Bitcat is a novel on-chain derivative protocol based on the BRC20 market and has garnered support from Binance Labs. It is supported by Copycat Finance in terms of technology.\n\nBuilt on top of the non-custodial asset trading protocol on the Bitcoin chain, Bitcat serves as a derivative trading platform for BRC20 assets using the RGB scheme and BTC Rollup technology. The platform aims to provide a decentralized exchange (Dex)-like trading experience for BRC20 derivatives, offering more possibilities for the development of the Bitcoin ecosystem and leading the way in emerging trends.',
    blocksLeft: '#Blocks Left: ',
    leftTexts: [
      {
        key: 'Launching Amount:',
        value: {
          bakery: '16,800,000',
          onecat: '16,800,000',
        },
        isValue: true,
      },
      {
        key: 'Price:',
        value: {
          bakery: '10,000 $BICA = 300 USD',
          onecat: '10,000 $BICA = 300 USD',
        },
        isValue: true,
      },
      {
        key: 'Start:',
        value: {
          bakery: 'Jan 02, 8 AM UTC',
          onecat: 'Jan 02, 8 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'End:',
        value: {
          bakery: 'Jan 02, 9 AM UTC',
          onecat: 'Jan 02, 9 AM UTC',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Website',
        url: 'https://bitcat.copycat.finance',
        icon: require('../../assets/ido/bitcat_website.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/CopycatFinance',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
      {
        name: 'Discord',
        url: 'https://discord.gg/copycatfinance',
        icon: require('../../assets/ido/bitcat_discord.png').default,
      },
    ],
  },
  {
    detailImg: require('../../assets/ido/bdin.png').default,
    detailImg_phone: require('../../assets/ido/bdin_phone.png').default,
    title: 'BendDAO-BDIN',
    supportedCoin: 'ETH, BAKE, DOGGY, PUNK',
    imgWebsite: require('../../assets/ido/bdin_icon.png').default,
    imgWebsiteBg: false,
    website: 'https://bitcat.copycat.finance',
    whitelist: false,
    des:
      '$BDIN is BendDAO’s BRC-20 Inscription, enhancing Bitcoin-related operations with a focus on lending and bridge. It’s set to revolutionize cross-chain bridges and collateralized lending, bringing enhanced DeFi liquidity to Bitcoin assets.\n\nTo spearhead innovation and foster growth within the BendDAO ecosystem, a strategic 30% of $BDIN is allocated to a development fund, ensuring the continuous evolution of the Bitcoin ecosystem.',
    blocksLeft: '#Blocks Left: ',
    leftTexts: [
      {
        key: 'Launching Amount:',
        value: {
          bakery: '5,000,000',
          onecat: '5,000,000',
        },
        isValue: true,
      },
      {
        key: 'Price:',
        value: {
          bakery: '20000 $BDIN = 100 USD',
          onecat: '20000 $BDIN = 100 USD',
        },
        isValue: true,
      },
      {
        key: 'Start:',
        value: {
          bakery: 'Jan 04, 8 AM UTC',
          onecat: 'Jan 04, 8 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'End:',
        value: {
          bakery: 'Jan 04, 9 AM UTC',
          onecat: 'Jan 04, 9 AM UTC',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Website',
        url: 'https://www.benddao.xyz/',
        icon: require('../../assets/ido/bdin_website.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/BendDAO',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
      {
        name: 'Telegram',
        url: 'https://t.me/BendDAO',
        icon: require('../../assets/ido/onecat_telegram.png').default,
      },
    ],
  },
  {
    detailImg: require('../../assets/ido/catnft.png').default,
    detailImg_phone: require('../../assets/ido/catnft_phone.png').default,
    title: 'Bitcoin Cats NFT',
    supportedCoin: 'ETH, BAKE, 1CAT',
    imgWebsite: require('../../assets/ido/catnft.gif').default,
    imgWebsiteBg: false,
    website: 'https://www.bitcoincats.world',
    whitelist: false,
    des:
      'Bitcoin Cats NFT is the collection of 10,000 pixelated cat artworks inscribed on Ordinals. They are a set of characters that represent a chill lifestyle. Built by cat lovers and community artists, Bitcoin Cats hopes to create delightful moments with cute art and spread them to every corner of the world.\n\nWL Round only supports ETH, in which each WL will be guaranteed at least 1 NFT allocation. Non-WL Round only supports $Bake and $1Cat, in which the NFTs will be distributed proportionally based on the $BAKE and $1CAT contributions. In case of oversubscription, the excess will be refunded. Participants will receive excess refunds within 24 hours after the sale. And the Bitcoin Cats NFT will be distributed in a few days on the Bitcoin network.',
    blocksLeft: '#Blocks Left: ',
    leftTexts: [
      {
        key: 'Launching Amount:',
        value: {
          eth: '8,500',
          bakery: '8,500',
          onecat: '8,500',
        },
        isValue: true,
      },
      {
        key: 'Price:',
        value: {
          eth: '0.015BTC (~0.3ETH)',
          bakery: '0.015BTC (~0.3ETH)',
          onecat: '0.015BTC (~0.3ETH)',
        },
        isValue: true,
      },
      {
        key: 'WL Round Start:',
        value: {
          eth: 'Jan 09, 8 AM UTC',
          bakery: 'Jan 09, 8 AM UTC',
          onecat: 'Jan 09, 8 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'non-WL Round Start:',
        value: {
          eth: 'Jan 09, 9 AM UTC',
          bakery: 'Jan 09, 9 AM UTC',
          onecat: 'Jan 09, 9 AM UTC',
        },
        isValue: true,
      },
      {
        key: 'End:',
        value: {
          eth: 'Jan 09, 10 AM UTC',
          bakery: 'Jan 09, 10 AM UTC',
          onecat: 'Jan 09, 10 AM UTC',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Website',
        url: 'https://www.bitcoincats.world',
        icon: require('../../assets/ido/onecat_website.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/BitcoinCats1Cat',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
      {
        name: 'Telegram',
        url: 'https://t.me/BitcoinCats1CAT',
        icon: require('../../assets/ido/onecat_telegram.png').default,
      },
    ],
  },
  {
    detailImg: require('../../assets/ido/opensky.png').default,
    detailImg_phone: require('../../assets/ido/opensky_phone.png').default,
    title: 'OpenSky',
    supportedCoin: 'ETH, BAKE, 1CAT',
    imgWebsite: require('../../assets/ido/opensky_icon.png').default,
    imgWebsiteBg: false,
    website: 'https://opensky.finance/',
    whitelist: false,
    des:
      'OpenSky Finance is the first hybrid NFT lending and borrowing protocol powered by Aave, offering peer-to-pool instant loans and peer-to-peer bespoke loans. It enables borrowers to seamlessly switch between these two types of loans in a single transaction.\n\nOpenSky Finance’s vision is to help NFT enthusiasts on Ethereum, Bitcoin and other leading chains to unlock and put their valuable NFTs to work with high capital efficiency and no price-based liquidation risk.',
    blocksLeft: '#Blocks Left: ',
    leftTexts: [
      {
        key: 'Launching Amount:',
        value: {
          bakery: 'up to 1,200,000',
          onecat: 'up to 1,200,000',
        },
        isValue: true,
      },
      {
        key: 'Value:',
        value: {
          bakery: '$480k USDT',
          onecat: '$480k USDT',
        },
        isValue: true,
      },
      {
        key: 'Price:',
        value: {
          bakery: 'FREE',
          onecat: 'FREE',
        },
        isValue: true,
      },
      {
        key: 'Start:',
        value: {
          bakery: 'Jan 18th, 8AM UTC',
          onecat: 'Jan 18th, 8AM UTC',
        },
        isValue: true,
      },
      {
        key: 'End:',
        value: {
          bakery: 'Jan 18th, 9AM UTC',
          onecat: 'Jan 18th, 9AM UTC',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Website',
        url: ' https://opensky.finance/',
        icon: require('../../assets/ido/opensky_website.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/OpenSkyFinance',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
      {
        name: 'Telegram',
        url: 'https://t.me/OpenSkyLabs',
        icon: require('../../assets/ido/onecat_telegram.png').default,
      },
    ],
  },
  {
    detailImg: require('../../assets/ido/bvm.png').default,
    detailImg_phone: require('../../assets/ido/bvm_phone.png').default,
    title: 'BitcoinVM',
    supportedCoin: 'ETH, BAKE, 1CAT',
    imgWebsite: require('../../assets/ido/bvm_icon.png').default,
    imgWebsiteBg: false,
    website: 'https://www.bitcoinvm.network',
    whitelist: false,
    des:
      'BitcoinVM is an EVM compatible Layer2 solution for Bitcoin, using BTC as the “gas” and the “native currency” within its ecosystem. It seamlessly connects Bitcoin and EVM ecosystems, thereby enhancing the utility of Bitcoin by bringing various DeFi applications into the realm and enabling a new era of BTCFi.\n\nBeyond being a singular Bitcoin Layer2 network, BitcoinVM also provides BVM Stack, which is a customizable platform for developers to easily build their own EVM-compatible Bitcoin Layer2 networks. Chains built on the BVM Stack can interact seamlessly with each other, fostering a robust interconnected ecosystem.',
    leftTexts: [
      {
        key: 'Launching Amount:',
        value: {
          eth: '2,000,000 $BVM',
          bakery: '2,000,000 $BVM',
          onecat: '2,000,000 $BVM',
          wBTC: '2,000,000 $BVM',
        },
        isValue: true,
      },
      {
        key: 'Protocol:',
        value: {
          eth: 'ERC20 & BVM Mainnet',
          bakery: 'ERC20 & BVM Mainnet',
          onecat: 'ERC20 & BVM Mainnet',
          wBTC: 'ERC20 & BVM Mainnet',
        },
        isValue: true,
      },
      {
        key: 'Supported Token:',
        value: {
          eth: 'ETH, wBTC, Bake, 1Cat',
          bakery: 'ETH, wBTC, Bake, 1Cat',
          onecat: 'ETH, wBTC, Bake, 1Cat',
          wBTC: 'ETH, wBTC, Bake, 1Cat',
        },
        isValue: true,
      },
      {
        key: 'Price:',
        value: {
          eth: 'TBD',
          wBTC: 'TBD',
          bakery: 'TBD',
          onecat: 'TBD',
        },
        isValue: true,
      },
      {
        key: 'Time:',
        value: {
          eth: 'TBD',
          wBTC: 'TBD',
          bakery: 'TBD',
          onecat: 'TBD',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Website',
        url: ' https://www.bitcoinvm.network',
        icon: require('../../assets/ido/bvm_website.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/BitcoinVM_BVM',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
      {
        name: 'Medium',
        url: 'https://bitcoinvm.medium.com',
        icon: require('../../assets/ido/onecat_telegram.png').default,
      },
    ],
  },
  {
    detailImg: require('../../assets/ido/pump.png').default,
    detailImg_phone: require('../../assets/ido/pump_phone.png').default,
    title: 'Big Pump',
    supportedCoin: '1CAT',
    imgWebsite: require('../../assets/ido/pump_icon.png').default,
    imgWebsiteBg: false,
    website: 'https://www.bitcoinvm.network',
    whitelist: false,
    des:
      "$PUMP is a meme coin on BNB Chain that represents what everyone in crypto is dreaming of: a Big Pump of their coins.\n\n$PUMP is absolutely a meme coin and it's all about having fun. It doesn't have any utility, there are no team to back it up.",
    leftTexts: [
      {
        key: 'Symbol:',
        value: {
          onecat: '$PUMP',
        },
        isValue: true,
      },
      {
        key: 'Chain:',
        value: {
          onecat: 'BNB Chain',
        },
        isValue: true,
      },
      {
        key: 'Total Supply:',
        value: {
          onecat: '4,444,444,444,444',
        },
        isValue: true,
      },
      {
        key: 'Start:',
        value: {
          onecat: 'Mar 21, 10am UTC',
        },
        isValue: true,
      },
      {
        key: 'End:',
        value: {
          onecat: 'Mar 22, 10am UTC',
        },
        isValue: true,
      },
    ],
    links: [
      {
        name: 'Medium',
        url: 'https://medium.com/p/83d488dbed47',
        icon: require('../../assets/ido/medium.png').default,
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/PumpBigPump',
        icon: require('../../assets/ido/onecat_twitter.png').default,
      },
    ],
  },
]
