import random from 'lodash/random'

// const nodes: any = {
//   1: ['https://mainnet.infura.io/v3/'],
//   5: ['https://goerli.infura.io/v3/'],
// }
const nodes: any = {
  1: ['https://mainnet.infura.io/v3/'],
  5: ['https://goerli.infura.io/v3/'],
  56: ['https://bsc-dataseed1.ninicoin.io', 'https://bsc-dataseed1.defibit.io', 'https://bsc-dataseed.binance.org'],
  97: [
    'https://data-seed-prebsc-1-s1.binance.org:8545',
    'https://data-seed-prebsc-2-s1.binance.org:8545',
    'https://data-seed-prebsc-1-s2.binance.org:8545',
    'https://data-seed-prebsc-2-s2.binance.org:8545',
    'https://data-seed-prebsc-1-s3.binance.org:8545',
    'https://data-seed-prebsc-2-s3.binance.org:8545',
  ],
  8453: ['https://developer-access-mainnet.base.org'],
  84531: ['https://goerli.base.org'],
}
const getBlockNode = (chainId: number) => {
  const ary = nodes[chainId || 1]
  const randomIndex = random(0, ary?.length - 1)
  return ary?.length > 0 && ary[randomIndex]
}
export default getBlockNode
