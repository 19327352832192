import { useCallback } from 'react'
import BigNumber from 'bignumber.js'

// hooks
import useYam from '../bakery/useYam'

// sushi
import {
  getOneCatAddress,
  getDoggyCoinAddress,
  getPunkxAddress,
  getBktAddress,
  getBusdAddress,
  getUsdtAddress,
  getBakeryAddress,
  getDoggyAddress,
  bakeBaseIDODepositToken,
  bakeBaseIDODepositBNB,
  token2NftMint,
  getDoggyBakeBaseDropContract,
  getToken2NftBaseContract,
  getSwapNFTContract,
  swapNft2Token,
  getOneCatDropContract,
  getWethAddress,
  getDoggyCoinDropContract,
  getBitLandDropContract,
  getBitCatDropContract,
  getBendDaoDropContract,
  getCatNFTContract,
  getOpenSkyContract,
  getBitcoinVMContract,
} from '../../sushi/utils'

import { IDOTYPE } from '../../constants/ido'

const useLaunchIdo = (tokenName: string, type?: string) => {
  const sushi = useYam()

  let tokenContract = getDoggyBakeBaseDropContract(sushi)
  switch (type) {
    case IDOTYPE.DOGGY:
      tokenContract = getDoggyBakeBaseDropContract(sushi)
      break
    case IDOTYPE.QUICKDOGGY:
      tokenContract = getToken2NftBaseContract(sushi)
      break
    case IDOTYPE.MINTDOGGY:
      tokenContract = getToken2NftBaseContract(sushi)
      break
    case IDOTYPE.DOGGYNFT:
      tokenContract = getSwapNFTContract(sushi)
      break
    case IDOTYPE.DOGGYMINTNFT:
      tokenContract = getToken2NftBaseContract(sushi)
      break
    case IDOTYPE.ONECAT:
      tokenContract = getOneCatDropContract(sushi)
      break
    case IDOTYPE.DOGGYCOIN:
      tokenContract = getDoggyCoinDropContract(sushi)
      break
    case IDOTYPE.BITLAND:
      tokenContract = getBitLandDropContract(sushi)
      break
    case IDOTYPE.BITCAT:
      tokenContract = getBitCatDropContract(sushi)
      break
    case IDOTYPE.BENDDAO:
      tokenContract = getBendDaoDropContract(sushi)
      break
    case IDOTYPE.CATNFT:
      tokenContract = getCatNFTContract(sushi)
      break
    case IDOTYPE.OPENSKY:
      tokenContract = getOpenSkyContract(sushi)
      break
    case IDOTYPE.BITCOINVM:
      tokenContract = getBitcoinVMContract(sushi)
      break
  }

  let address = getBakeryAddress(sushi)
  switch (tokenName) {
    case 'busd':
      address = getBusdAddress(sushi)
      break
    case 'usdt':
      address = getUsdtAddress(sushi)
      break
    case 'bakery':
      address = getBakeryAddress(sushi)
      break
    case 'bkt':
      address = getBktAddress(sushi)
      break
    case 'doggy':
      address = getDoggyAddress(sushi)
      break
    case 'eth':
      address = getWethAddress(sushi)
      break
    case 'doggycoin':
      address = getDoggyCoinAddress(sushi)
      break
    case 'punkx':
      address = getPunkxAddress(sushi)
      break
    case 'onecat':
      address = getOneCatAddress(sushi)
      break
  }

  const handleIdo = useCallback(
    async (account, amount: any, nfts?: any, action?: string) => {
      if (tokenName && account && amount) {
        const time = Date.parse(new Date() + '') / 1000 + 1800
        try {
          const pet = [IDOTYPE.DOGGYNFT].includes(type as any)
            ? swapNft2Token(
                tokenContract,
                account,
                new BigNumber(nfts?.length).multipliedBy(new BigNumber(10).pow(18)).toString(),
                nfts,
                new BigNumber(amount * (action === 'sell' ? 0.91 : 1.01))
                  .multipliedBy(new BigNumber(10).pow(18))
                  .integerValue()
                  .toString(),
                time,
                action
              )
            : [IDOTYPE.QUICKDOGGY, IDOTYPE.MINTDOGGY, IDOTYPE.DOGGYMINTNFT].includes(type as any)
            ? token2NftMint(
                tokenContract,
                account,
                new BigNumber(amount).multipliedBy(new BigNumber(10).pow(18)).toString(),
                nfts,
                action
              )
            : tokenName === 'eth'
            ? await bakeBaseIDODepositBNB(
                tokenContract,
                account,
                new BigNumber(amount).multipliedBy(new BigNumber(10).pow(18)).toString()
              )
            : await bakeBaseIDODepositToken(
                tokenContract,
                account,
                address,
                new BigNumber(amount).multipliedBy(new BigNumber(10).pow(18)).toString()
              )
          return pet
        } catch (e) {
          console.log('useIdoPet', e)
          return false
        }
      } else {
        return false
      }
    },
    [tokenContract, tokenName, address, type]
  )

  return { onIdo: handleIdo }
}

export default useLaunchIdo
