import Contact from 'assets/svg/contact.svg'
import Contact_dark from 'assets/svg/contact_dark.svg'
import Earning from 'assets/svg/earning.svg'
import Earning_dark from 'assets/svg/earning_dark.svg'
import Earning_select from 'assets/svg/earning_select.svg'
// import Exchange from 'assets/svg/exchange.svg'
// import Exchange_dark from 'assets/svg/exchange_dark.svg'
// import Exchange_select from 'assets/svg/exchange_select.svg'
// import Home from 'assets/svg/home.svg'
// import Home_dark from 'assets/svg/home_dark.svg'
// import Home_select from 'assets/svg/home_select.svg'
import NFTSwap from 'assets/svg/nftswap.svg'
import NFTSwap_dark from 'assets/svg/nft_dark.svg'
import NFTSwap_select from 'assets/svg/nft_select.svg'
import Medium from 'assets/svg/medium.svg'
import Medium_dark from 'assets/svg/mediumdark.svg'
import Trade from 'assets/svg/trade.svg'
import Trade_dark from 'assets/svg/trade_dark.svg'
import Trade_select from 'assets/svg/trade_select.svg'
import Launchpad from 'assets/svg/launchpad.svg'
import Launchpad_dark from 'assets/svg/launchpad_dark.svg'
import Launchpad_select from 'assets/svg/launchpad_select.svg'
import Triangle_down from 'assets/svg/triangle_down.svg'
import Triangle_up from 'assets/svg/triangle_up.svg'
import Twitter from 'assets/svg/twitter.svg'
import Twitter_dark from 'assets/svg/twitter_dark.svg'
import Telegram from 'assets/svg/telegram.svg'
import Telegram_dark from 'assets/svg/telegram_dark.svg'
import ComingSoon from 'assets/svg/coming-soon.svg'
// import CheckTest from 'pages/CheckTest'
// import LimitIp from 'pages/LimitIp'
import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ExternalLink, MEDIA_WIDTHS } from 'theme'
import { useDarkModeManager } from '../../state/user/hooks'
import { stopPropagation } from '../../utils'
import React from 'react'
import ReactDOM from 'react-dom'
import LimitIp from '../../pages/LimitIp'

const activeClassName = 'ACTIVENAV'
const galleryAboutLink = 'galleryAboutLink'
const bakeAiAboutLink = 'bakeAiAboutLink'
const contactClassName = 'ACTIVECALSS'
const nftClassName = 'NFTCLASS'

const NavElement = styled.nav`
  width: 100%;
  height: calc(100vh - 61px);
  overflow: auto;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0px 2px 10px rgba(171, 133, 115, 0.16);
  background: ${({ theme }) => theme.bsBg36};
  position: relative;
  z-index: 1;

  &.${activeClassName} {
    ${({ theme }) => theme.mediaWidth.upToMedium`
    &:before{
        content: "";
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: -2;
        background: rgba(0,0,0,0.3);
    }
    &:after{
      content: "";
      position: fixed;
      width: 248px;
      z-index: -1;
      background: ${({ theme }) => theme.bsBg36};
      left: 0px;
      height: calc(100vh - 61px);
      top: 61px;
    }
  `};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow-x: auto;
  `};
`

const NavItem = styled(NavLink)`
  position: relative;
  color: ${({ theme }) => theme.bsText20};
  text-decoration: none;
  white-space: nowrap;
  display: block;
  padding: 0px 20px;
  height: 72px;
  line-height: 72px;
  margin: 10px 0px;

  &:hover {
    background: linear-gradient(305.04deg, #fff6b3 -43.12%, #ffb067 70.33%);
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.bsText39};
    background: linear-gradient(305.04deg, #ffd600 -43.12%, #ff7a00 70.33%);
    position: relative;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
  `};
`

const AboutLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.bsText20};
  font-weight: bold;
  text-decoration: none;
  display: block;
  padding: 0px 20px;
  height: 54px;
  line-height: 54px;
  margin: 10px 0px;
  white-space: nowrap;
  font-family: 'Roboto-Medium', 'Inter var', sans-serif;
  &:hover {
    background: linear-gradient(305.04deg, #fff6b3 -43.12%, #ffb067 70.33%);
  }

  &.${galleryAboutLink} {
    padding-left: 54px;
    height: 50px;
    line-height: 50px;
    color: inherit;
    display: inline;
    padding: 0px;
    margin-left: 8px;
    color: ${({ theme }) => theme.bsText40};
    &:hover {
      color: #ffffff;
    }
  }

  &.${bakeAiAboutLink} {
    padding-left: 54px;
    height: 50px;
    line-height: 50px;
    color: inherit;
    display: inline;
    padding: 0px;
    margin-left: 8px;
    color: ${({ theme }) => theme.bsText45};
    &:hover {
      color: #ffffff;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    margin-right: 8px;
  `};
`

const TriggerMenu = styled.div`
  position: relative;
  color: ${({ theme }) => theme.bsText20};
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  padding: 0px 20px;
  height: 72px;
  line-height: 72px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(305.04deg, #fff6b3 -43.12%, #ffb067 70.33%);
  }
`

const MenuElement = styled.div`
  background: ${({ theme }) => theme.bsBg31};
  overflow: hidden;
  transition: height 0.3s linear;
  height: 0px;
  border: 0px;
  box-shadow: 0px 2px 8px rgba(171, 133, 115, 0.16) inset;
  &.${activeClassName} {
    height: 74px;
  }
  &.${contactClassName} {
    height: 202px;
  }
  &.${nftClassName} {
    height: 330px;
    overflow: auto;
  }
`

const IconNav = styled.img`
  vertical-align: middle;
`

const IconTriangle = styled.img`
  position: absolute;
  right: 12px;
  top: 8px;
  bottom: 0px;
  margin: auto;
`

const TextTitle = styled.span`
  margin-left: 8px;
  vertical-align: middle;
  position: relative;
  img {
    position: absolute;
    top: -24px;
    right: -70px;
  }
`

export default function Nav({ triggerFold, isFold }: { triggerFold: (fold: boolean) => void; isFold: boolean }) {
  const [isDark] = useDarkModeManager()

  const location = useLocation()

  // const [isTrade, setIsTrade] = useState(true)
  const [isContact, setIsContact] = useState(true)

  useEffect(() => {
    const wrapper = document.createElement('div')
    ReactDOM.render(<LimitIp />, wrapper)
    document.body.appendChild(wrapper)
    if (window.location.host.indexOf('bakeryswap.io') >= 0) {
      const wrapper = document.createElement('div')
      // ReactDOM.render(<CheckTest />, wrapper)
      document.body.appendChild(wrapper)
    }
    // if (location.pathname === '/swap' || location.pathname === '/pool') {
    //   setIsTrade(true)
    // }
  }, [location.pathname])

  // const triggerTrade = (trade: boolean, event: any) => {
  //   stopPropagation(event)
  //   isFold ? setIsTrade(true) : setIsTrade(trade)
  //   triggerFold(false)
  // }

  const triggerContact = (contact: boolean, event: any) => {
    stopPropagation(event)
    isFold ? setIsContact(true) : setIsContact(contact)
    triggerFold(false)
  }

  return (
    <>
      <NavElement
        onClick={
          document.body.offsetWidth <= +MEDIA_WIDTHS.upToMedium
            ? () => triggerFold(!isFold)
            : () => {
                return null
              }
        }
      >
        {/* <NavItem to="/" className={location.pathname === '/' ? activeClassName : ''}>
          <IconNav src={location.pathname === '/' ? Home_select : isDark ? Home_dark : Home} style={{ height: 24 }} />
          {!isFold && <TextTitle>Home</TextTitle>}
        </NavItem> */}
        {/* <TriggerMenu
          onClick={(e) => {
            triggerTrade(!isTrade, e)
          }}
          className={isTrade ? activeClassName : ''}
        >
          <IconNav src={isDark ? Trade_dark : Trade} style={{ height: 24 }} />
          {!isFold && (
            <>
              <TextTitle>Trade</TextTitle>
              <IconTriangle src={!isTrade ? Triangle_up : Triangle_down} style={{ height: 8 }} />
            </>
          )}
        </TriggerMenu>
        {!isFold && (
          <MenuElement className={isTrade ? activeClassName : ''}>
            <NavItem
              to="/swap"
              className={location.pathname === '/swap' ? activeClassName : ''}
              style={{ paddingLeft: '54px', fontWeight: 500 }}
            >
              <IconNav
                src={location.pathname === '/swap' ? Exchange_select : isDark ? Exchange_dark : Exchange}
                style={{ height: 24 }}
              />
              <TextTitle>Exchange</TextTitle>
            </NavItem>
          </MenuElement>
        )} */}
        <NavItem to="/swap" className={location.pathname === '/swap' ? activeClassName : ''}>
          <IconNav
            src={location.pathname === '/swap' ? Trade_select : isDark ? Trade_dark : Trade}
            style={{ height: 24 }}
          />
          {!isFold && <TextTitle>Trade</TextTitle>}
        </NavItem>
        <NavItem to="/nftswap" className={location.pathname === '/nftswap' ? activeClassName : ''}>
          <IconNav
            src={location.pathname === '/nftswap' ? NFTSwap_select : isDark ? NFTSwap_dark : NFTSwap}
            style={{ height: 24 }}
          />
          {!isFold && (
            <TextTitle>
              NFT Swap
              {/* <img src={ComingSoon} /> */}
            </TextTitle>
          )}
        </NavItem>
        <NavItem to="" className={location.pathname === '/earning' ? activeClassName : ''}>
          <IconNav
            src={location.pathname === '/earning' ? Earning_select : isDark ? Earning_dark : Earning}
            style={{ height: 24 }}
          />
          {!isFold && (
            <TextTitle>
              Earning
              <img src={ComingSoon} />
            </TextTitle>
          )}
        </NavItem>
        {/* <NavItem to="/launchpad" className={location.pathname === '/launchpad' ? activeClassName : ''}>
          <IconNav
            src={location.pathname === '/launchpad' ? Launchpad_select : isDark ? Launchpad_dark : Launchpad}
            style={{ height: 24 }}
          />
          {!isFold && (
            <TextTitle>
              Launchpad
            </TextTitle>
          )}
        </NavItem> */}
        <TriggerMenu
          onClick={(e) => {
            triggerContact(!isContact, e)
          }}
        >
          <IconNav src={isDark ? Contact_dark : Contact} style={{ height: 24 }} />
          {!isFold && <TextTitle>Contact</TextTitle>}
          {!isFold && <IconTriangle src={!isContact ? Triangle_up : Triangle_down} style={{ height: 8 }} />}
        </TriggerMenu>
        {!isFold && (
          <MenuElement className={isContact ? contactClassName : ''}>
            <AboutLink href="https://twitter.com/bakery_swap" style={{ paddingLeft: '60px', fontWeight: 500 }}>
              <IconNav src={isDark ? Twitter_dark : Twitter} style={{ height: 24 }} />
              <TextTitle>Twitter</TextTitle>
            </AboutLink>
            <AboutLink href="https://medium.com/@BakerySwap" style={{ paddingLeft: '60px', fontWeight: 500 }}>
              <IconNav src={isDark ? Medium_dark : Medium} style={{ height: 24 }} />
              <TextTitle>Medium</TextTitle>
            </AboutLink>
            <AboutLink href="https://t.me/bakeryswap" style={{ paddingLeft: '60px', fontWeight: 500 }}>
              <IconNav src={isDark ? Telegram_dark : Telegram} style={{ height: 24 }} />
              <TextTitle>Telegram</TextTitle>
            </AboutLink>
          </MenuElement>
        )}
      </NavElement>
    </>
  )
}
